import { ref, computed, nextTick } from 'vue';
import { useRoute } from 'vue-router';

import {
  searchImageByOss,
} from '@/modules/resource-manage/search-image/api';
import { ISearchImageByOssItem, ISearchImageByOssReq } from '@/modules/resource-manage/search-image/api/type.d';
import { trackingExposure, trackingClick } from '@/utils/tracking';
import { usePagination } from './use-pagination';
import { IPosition } from '../components/image-cropper/types';

export default () => {
  const $route = useRoute();
  const imageUrl = ref(decodeURIComponent($route.query.url as string));
  const position = ref<IPosition>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  }); // 框选框坐标数组
  const hasFetched = ref(false);
  const {
    handleScrollToPage,
    initPagination,
    pageSize,
    reqPageIndex,
    currentViewPage,
  } = usePagination();
  const total = ref(0);
  const finish = ref(false);
  const goodsList = ref<ISearchImageByOssItem[]>([]);
  let dataList: ISearchImageByOssItem[] = [];

  const getList = async () => {
    if (!imageUrl.value) Promise.reject();
    try {
      let param: ISearchImageByOssReq = {
        count: 100,
        path: imageUrl.value,
        channelId: $route.query.channelId as string,
      };
      let bbox: number[] = [];
      if (position.value.width > 0) {
        const x2 = position.value.x + position.value.width;
        const y2 = position.value.y + position.value.height;
        bbox = [position.value.x, position.value.y, x2, y2];
        param = {
          ...param,
          bbox,
        };
      }
      const res = await searchImageByOss(param);
      if (res.data?.bbox && position.value.width === 0) {
        position.value.x = +res.data?.bbox[0];
        position.value.y = +res.data?.bbox[1];
        position.value.width = +res.data?.bbox[2] - +res.data?.bbox[0];
        position.value.height = +res.data?.bbox[3] - +res.data?.bbox[1];
      }
      hasFetched.value = true;
      dataList = res.data?.pictureSearchResps || [];
      goodsList.value = dataList.slice(0, pageSize.value);
      trackingExposure(goodsList.value, 'key');
      total.value = res.data && res.data.totalCount ? Number(res.data.totalCount) : 0;
      if (Number(res.data.totalCount) <= reqPageIndex.value * pageSize.value) {
        finish.value = true;
      } else {
        finish.value = false;
      }
      trackingClick({
        eventName: '以图搜图-搜索结果',
        url: imageUrl.value,
        coordinate: bbox,
        total: Number(res.data.totalCount),
        goodList: dataList.map(item => item.spuPictureLink),
      });
      return Promise.resolve();
    } catch (e) {
      console.log('获取首页列表 error', e);
      hasFetched.value = true;
      return Promise.reject();
    }
  };
  const reload = () => {
    reqPageIndex.value = 1;
    goodsList.value = [];
    getList();
  };
  // 懒加载
  const listLoading = ref<boolean>(false);
  const listDisabled = computed(() => listLoading.value || finish.value);
  const loadMore = async () => {
    listLoading.value = true;
    reqPageIndex.value += 1;
    const list = dataList.slice((reqPageIndex.value - 1) * pageSize.value, reqPageIndex.value * pageSize.value);
    goodsList.value = goodsList.value.concat(list);
    trackingExposure(list, 'key');
    listLoading.value = false;
    if (total.value <= reqPageIndex.value * pageSize.value) {
      finish.value = true;
    } else {
      finish.value = false;
    }
  };
  const handleCurrentSizeChange = async (val: number) => {
    try {
      if (listLoading.value) return;
      if (val > reqPageIndex.value) {
        listLoading.value = true;
        const list = dataList.slice(
          reqPageIndex.value * pageSize.value, val * pageSize.value,
        );
        goodsList.value.push(...list);
        trackingExposure(list, 'key');
        if (Number(total.value) <= val * pageSize.value) {
          finish.value = true;
        } else {
          finish.value = false;
        }
        reqPageIndex.value = val;
        await nextTick();
        handleScrollToPage(val);
      } else {
        handleScrollToPage(val);
      }
    } catch (error) {
      console.log('获取列表数据错误', error);
    }
    listLoading.value = false;
  };
  const isEmpty = computed(() => {
    return !goodsList.value.length && !listLoading.value;
  });

  return {
    hasFetched,
    imageUrl,
    position,
    handleCurrentSizeChange,
    total,
    initPagination,
    pageSize,
    currentViewPage,
    goodsList,
    listLoading,
    finish,
    listDisabled,
    isEmpty,
    loadMore,
    reload,
  };
};
