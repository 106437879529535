// 商品状态
export enum GOODS_STATE_ENUM {
  /* 在售 */
  IN_SALE = '1',
  /* 下架 */
  OFF_SALE = '2',
  /* 预售 */
  BOOKING = '3',
  /* 售罄 */
  SALE_OUT = '4',
}
export const GOODS_STATE_LIST = [
  { label: '在售', value: GOODS_STATE_ENUM.IN_SALE },
  { label: '预售', value: GOODS_STATE_ENUM.BOOKING },
  { label: '下架', value: GOODS_STATE_ENUM.OFF_SALE },
  { label: '售罄', value: GOODS_STATE_ENUM.SALE_OUT },
];
