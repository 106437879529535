import { createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-39375a18"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "image-cropper" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      ref: "crppperEl",
      src: _ctx.path,
      class: "crppper"
    }, null, 8, _hoisted_2)
  ], 512)), [
    [_directive_loading, _ctx.loading]
  ])
}