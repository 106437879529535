
import { defineComponent, ref, onMounted, nextTick, PropType, onBeforeUnmount, watch } from 'vue';
import Cropperjs from 'cropperjs';
import 'cropperjs/dist/cropper.min.css';

import { IPosition } from './types';

export default defineComponent({
  props: {
    path: {
      type: String,
      default: 'https://oss.yunbanfang.cn/tiangong_fcadfbf4bcd047b6a19fb0481c31c7cd.jpeg',
      required: true,
    },
    position: {
      type: Object as PropType<IPosition>,
      default: () => ({
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      }),
    },
    hasFetched: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['change'],

  setup(props, { emit }) {
    const loading = ref(false);
    const crppperEl = ref();
    const Cropper = ref<InstanceType<typeof Cropperjs>|null>();

    const setData = () => {
      if (props.hasFetched) {
        Cropper.value?.crop();
        if (props.position.width > 0) {
          const { x, y, width, height } = props.position;
          Cropper.value?.setData({
            x,
            y,
            width,
            height,
          });
        } else {
          Cropper.value?.setData({
            x: 0,
            y: 0,
            width: Cropper.value?.getImageData().naturalWidth,
            height: Cropper.value?.getImageData().naturalHeight,
          });
        }
        loading.value = false;
      }
    };

    watch(() => props.hasFetched, (n) => {
      if (n) {
        setData();
      }
    });

    onMounted(async () => {
      loading.value = true;
      await nextTick();
      Cropper.value = new Cropperjs(crppperEl.value, {
        viewMode: 1,
        dragMode: 'none',
        initialAspectRatio: 1,
        aspectRatio: NaN,
        preview: '.before',
        background: false,
        zoomOnWheel: false,
        minCropBoxWidth: 5,
        minCropBoxHeight: 5,
        autoCrop: false,
        ready: () => {
          setData();
        },
        cropend: () => {
          emit('change', Cropper.value?.getData());
        },
      });
    });

    onBeforeUnmount(() => {
      Cropper.value?.destroy();
      Cropper.value = null;
      loading.value = false;
    });

    return {
      loading,
      crppperEl,
    };
  },
});
