
import { defineComponent, ref, nextTick, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import Cropperjs from 'cropperjs';
import { ElMessage } from 'element-plus';

import SelectImgDialog from '@/components/select-img-dialog/index.vue';
import Goods from './components/goods.vue';
import ImageCropper from './components/image-cropper/index.vue';

import {
  getDesignTaskOptions,
} from '@/modules/task-manage/design-task/api/index';
import {
  IGetDesignTaskOptionsRes,
} from '@/modules/task-manage/design-task/api/types';
import {
  selectImageToTask,
} from '@/modules/resource-manage/search-image/api';
import { ISearchImageByOssItem } from '@/modules/resource-manage/search-image/api/type.d';
import { GOOD_TYPE_ENUM, CHANNEL_ID_ENUM } from '@/components/select-img-dialog/constant';
import useGoodsList from './composables/use-goods-list';
import { useCommonStore } from '@/store/common';
import { CHANNEL_ENUM } from '@/constant/global';
import { trackingClick } from '@/utils/tracking';

export default defineComponent({
  components: {
    Goods,
    SelectImgDialog,
    ImageCropper,
  },
  setup() {
    const commonState = useCommonStore();
    const $router = useRouter();
    const $route = useRoute();
    const goodsEl = ref<null|HTMLElement>(null);
    // 列表查询
    const {
      imageUrl,
      position,
      handleCurrentSizeChange,
      goodsList,
      reload,
      listLoading,
      finish,
      listDisabled,
      loadMore,
      isEmpty,
      initPagination,
      currentViewPage,
      pageSize,
      total,
      hasFetched,
    } = useGoodsList();
    const designTaskOptions = ref<IGetDesignTaskOptionsRes[]>([]);
    const getDesignOptions = async () => {
      try {
        const { data } = await getDesignTaskOptions();
        designTaskOptions.value = data;
      } catch (error) {
        console.log(error);
      }
    };
    getDesignOptions();
    const init = async () => {
      await nextTick();
      initPagination(goodsEl.value!);
      reload();
    };
    onMounted(() => {
      init();
      commonState.updateSearchImageChannelId($route.query.channelId as CHANNEL_ENUM);
    });

    // 选图
    const selectImgDialogConfig = ref({
      visible: false,
      urls: [] as string[],
      categoryClass1: '',
      spu: '',
      channelId: '',
    });
    const handleSelectImgItem = async (row: ISearchImageByOssItem, type: 'quick' | 'normal') => {
      const id = localStorage.getItem('quick_design_id');
      let eventDetails: { [key: string]: string; } = {
        eventName: '选图',
        goodId: row.key || '',
      };
      if (type === 'quick') {
        eventDetails = {
          ...eventDetails,
          eventName: '快速选图',
          taskId: id || '',
        };
      }
      trackingClick(eventDetails);
      if (type === 'quick' && id) {
        await selectImageToTask({
          id,
          channelId: row.channelId,
          categoryClass: row.categoryClass2Name || row.categoryClass1Name,
          key: row.key,
        });
        const task = designTaskOptions.value.find(v => v.id === id);
        if (task) {
          ElMessage.success(`已添加到想法${task.designTaskName}`);
        }
        return;
      }
      selectImgDialogConfig.value.spu = row.key;
      selectImgDialogConfig.value.urls = [row.key];
      selectImgDialogConfig.value.channelId = row.channelId;
      selectImgDialogConfig.value.categoryClass1 = row.categoryClass2Name || row.categoryClass1Name;
      selectImgDialogConfig.value.visible = true;
    };

    // 点击图片
    const handleGoodItem = (row: ISearchImageByOssItem) => {
      trackingClick({
        eventName: '查看商品详情',
        goodId: row.key,
      });
      let newPage = $router.resolve({
        name: 'ResourceManageStyleList',
        query: {
          skc: encodeURIComponent(row.key),
          channelId: row.channelId,
        },
      });
      if (row.channelId === CHANNEL_ID_ENUM.DIAN_SHANG) {
        newPage = $router.resolve({
          name: 'DomesticGoodsDetail',
          params: {
            spu: row.key,
          },
        });
      } else if (row.channelId === CHANNEL_ID_ENUM.DOU_YIN) {
        newPage = $router.resolve({
          name: 'DoupinkuDetail',
          query: {
            spu: row.key,
          },
        });
      }
      window.open(newPage.href);
    };

    // 选图弹窗-确定
    const handleSelectSuccess = (id: string) => {
      trackingClick({
        eventName: '选图弹窗-点击确定',
        goodId: selectImgDialogConfig.value.spu,
        taskId: id,
      });
    };

    // 找相似
    const handleSearch = (row: ISearchImageByOssItem) => {
      trackingClick({
        eventName: '找相似',
        goodId: row.key,
      });
      const newPage = $router.resolve({
        name: 'SearchImageList',
        query: {
          url: encodeURIComponent(row.spuPictureLink),
          channelId: `${row.channelId}` === '2' || `${row.channelId}` === '3' ? '1' : row.channelId,
        },
      });
      window.open(newPage.href);
    };

    // 框选改变
    const handleCropChange = (obj: Cropperjs.Data) => {
      trackingClick({
        eventName: '框选',
      });
      position.value = obj;
      reload();
    };

    return {
      hasFetched,
      imageUrl,
      position,
      total,
      currentViewPage,
      pageSize,
      goodsEl,
      designTaskOptions,
      selectImgDialogConfig,
      goodsList,
      listLoading,
      finish,
      listDisabled,
      loadMore,
      isEmpty,
      handleSelectImgItem,
      handleCurrentSizeChange,
      handleGoodItem,
      handleSelectSuccess,
      handleSearch,
      handleCropChange,
    };
  },
  render() {
    return (
      <div>
        <main class="container">
          <div class="cropper">
            {this.imageUrl ? <image-cropper
              path={this.imageUrl}
              position={this.position}
              hasFetched={this.hasFetched}
              onChange={this.handleCropChange}
            /> : <div class="cropper_bg" />}
          </div>
          <section style={{ flex: 1 }}>
            <div class="result">搜索到 {this.total || 0} 款类似商品</div>
            <div
              class="goods-container"
              ref="goodsEl"
              v-infinite-scroll={this.loadMore}
              infinite-scroll-distance={50}
              infinite-scroll-disabled={this.listDisabled}
              infinite-scroll-immediate={false}
            >
              {this.goodsList.map((v, i) => (
                <goods
                  key={i}
                  data={v}
                  onSelectImg={this.handleSelectImgItem}
                  onImage={this.handleGoodItem}
                  onSearch={this.handleSearch}
                ></goods>
              ))}
            </div>
            {this.isEmpty ? (
              <empty content="暂无数据，换张图搜索试试吧" />
            ) : (
              <div class="load-other">
                {this.listLoading && (
                  <p><i class="el-icon-loading"></i> 加载中...</p>
                )}
                {this.finish && (
                  <p>已经到底了</p>
                )}
              </div>
            )}
            <list-pagination
              total={this.total}
              current={this.currentViewPage}
              pageSize={this.pageSize}
              onCurrentChange={this.handleCurrentSizeChange}
            />
          </section>
        </main>
        <select-img-dialog
          v-model={[this.selectImgDialogConfig.visible, 'visible']}
          urls={this.selectImgDialogConfig.urls}
          categoryClass1={this.selectImgDialogConfig.categoryClass1}
          options={this.designTaskOptions}
          goodType={GOOD_TYPE_ENUM.DEFAULT}
          channelId={this.selectImgDialogConfig.channelId}
          onSuccess={this.handleSelectSuccess}
        />
      </div>
    );
  },
});
