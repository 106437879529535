
import { defineComponent, PropType } from 'vue';

import { formatMillion } from '@/utils/helper';
import { ISearchImageByOssItem } from '@/modules/resource-manage/search-image/api/type.d';
import {
  GOODS_STATE_ENUM,
  GOODS_STATE_LIST,
} from '../../../constant';
import usePermissionConfig from '../../../use-permission-config';

export default defineComponent({
  emits: ['select-img', 'image', 'search'],
  props: {
    data: {
      type: Object as PropType<ISearchImageByOssItem>,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const permissionConfig = usePermissionConfig();
    const handleSelectImgItem = (row: ISearchImageByOssItem, type: 'quick'|'normal' = 'normal') => {
      emit(
        'select-img',
        row,
        type,
      );
    };
    const handleImageItem = (row: ISearchImageByOssItem) => {
      emit('image', row);
    };

    const handleSearch = (row: ISearchImageByOssItem) => {
      emit('search', row);
    };

    return {
      permissionConfig,
      handleImageItem,
      GOODS_STATE_ENUM,
      GOODS_STATE_LIST,
      handleSelectImgItem,
      handleSearch,
      formatMillion,
    };
  },
  components: {},
});
